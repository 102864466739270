<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader style="font-weight:bold;">
          {{ itemVal.name }}
          <div class="float-right" style="color:#ff0000;" @click="onRemoveItem">
            <CIcon name="cil-trash" size="lg" />
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="5" class="divider">
              <CInput label="Name" v-model="itemVal.name" />

              <div><label>Enabled?</label></div>
              <CSwitch
                class="mr-1"
                color="success"
                shape="pill"
                :checked.sync="itemVal.item_enabled"
              />
              <CInput
                label="Prep Time"
                v-model="itemVal.prep_time"
                append="min"
              />
              <CInput label="Price" v-model="itemVal.price" prepend="$" />
              <CInput label="Sub-Category" v-model="itemVal.category" />
              <CTextarea
                label="Description"
                v-model="itemVal.description"
                type="multiline"
              />
              <label>Item Image</label><br />
              <CImg
                :src="itemVal.image_url"
                fluid
                thumbnail
                v-if="!isImageEdited"
              />
              <CImg
                :src="imageData"
                fluid
                thumbnail
                v-if="isImageEdited"
              /><br />
              <CInputFile
                horizontal
                custom
                @change="onFileSelected"
                class="pl-1 pt-2"
              />
            </CCol>
            <CCol lg="7">
              <h4>Options</h4>
              <CRow class="tiny-header">
                <CCol col="1"></CCol>
                <CCol col="3">Name</CCol>
                <CCol col="3">Req./Opt.</CCol>
                <CCol col="2">Min</CCol>
                <CCol col="2">Max</CCol>
              </CRow>
              <draggable
                v-model="itemVal.options"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <div
                    v-for="(option, optionIndex) in itemVal.options"
                    :key="option.category_number"
                    :value="option.category_number"
                  >
                    <CRow
                      style="border-bottom: 1px solid lightgray;"
                      class="menu-item small-text"
                    >
                      <CCol
                        col="1"
                        v-if="isCollapsed != 'optionCategory' + optionIndex"
                        @click="isCollapsed = 'optionCategory' + optionIndex"
                      >
                        <CIcon name="cis-caret-right" size="sm" />
                      </CCol>
                      <CCol
                        col="1"
                        v-if="isCollapsed == 'optionCategory' + optionIndex"
                        @click="isCollapsed = ''"
                      >
                        <CIcon name="cis-caret-bottom" size="sm" />
                      </CCol>
                      <CCol col="3">
                        <CInput
                          v-if="isEditing == 'optionCategory' + optionIndex"
                          v-model="option.category"
                          @blur="handleBlur('optionCategory' + optionIndex)"
                          @keyup.enter="isEditing = 'optionType' + optionIndex"
                          @keydown.prevent.tab="
                            isEditing = 'optionType' + optionIndex
                          "
                          v-focus
                        />
                        <div v-else>
                          <div
                            @click="isEditing = 'optionCategory' + optionIndex"
                          >
                            {{ option.category }}
                          </div>
                        </div>
                      </CCol>
                      <CCol col="3" class="small-text">
                        <CSelect
                          placeholder="-- Type --"
                          :value.sync="option.type"
                          v-if="isEditing == 'optionType' + optionIndex"
                          @blur="handleBlur('optionType' + optionIndex)"
                          @change="isEditing = 'optionMin' + optionIndex"
                          @keydown.prevent.tab="
                            isEditing = 'optionMin' + optionIndex
                          "
                          :options="[
                            { value: 'Required', label: 'Required' },
                            { value: 'Optional', label: 'Optional' },
                          ]"
                          v-focus
                        >
                        </CSelect>
                        <div v-else>
                          <div @click="isEditing = 'optionType' + optionIndex">
                            {{ option.type }}
                          </div>
                        </div>
                      </CCol>
                      <CCol col="2">
                        <CSelect
                          placeholder="Min"
                          :value.sync="option.min"
                          v-if="isEditing == 'optionMin' + optionIndex"
                          @blur="handleBlur('optionMin' + optionIndex)"
                          @change="isEditing = 'optionMax' + optionIndex"
                          @keydown.tab.exact="
                            isEditing = 'optionMax' + optionIndex
                          "
                          :options="[
                            { value: '0', label: '0' },
                            { value: '1', label: '1' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                            { value: '5', label: '5' },
                            { value: '6', label: '6' },
                            { value: '7', label: '7' },
                            { value: '8', label: '8' },
                            { value: '9', label: '9' },
                            { value: '10', label: '10' },
                          ]"
                          v-focus
                        >
                        </CSelect>
                        <div v-else>
                          <div @click="isEditing = 'optionMin' + optionIndex">
                            {{ option.min }}
                          </div>
                        </div>
                      </CCol>
                      <CCol col="2">
                        <CSelect
                          placeholder="Max"
                          :value.sync="option.max"
                          v-if="isEditing == 'optionMax' + optionIndex"
                          @blur="handleBlur('optionMax' + optionIndex)"
                          @change="$event.target.blur()"
                          :options="[
                            { value: '0', label: '0' },
                            { value: '1', label: '1' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                            { value: '5', label: '5' },
                            { value: '6', label: '6' },
                            { value: '7', label: '7' },
                            { value: '8', label: '8' },
                            { value: '9', label: '9' },
                            { value: '10', label: '10' },
                          ]"
                          v-focus
                        >
                        </CSelect>
                        <div v-else>
                          <div @click="isEditing = 'optionMax' + optionIndex">
                            {{ option.max }}
                          </div>
                        </div>
                      </CCol>
                      <CCol
                        lg="1"
                        class="text-right"
                        @click="onOptionsDelete(option)"
                      >
                        <CIcon name="cil-trash" size="sm" />
                      </CCol>
                    </CRow>
                    <CCollapse
                      :show="isCollapsed === 'optionCategory' + optionIndex"
                    >
                      <draggable
                        v-model="option.items"
                        v-bind="dragSubOptions"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <transition-group
                          type="transition"
                          :name="!drag ? 'flip-list' : null"
                        >
                          <CRow
                            v-for="(item, itemIndex) in option.items"
                            :key="item.item_number"
                            :value="item.item_number"
                            class="menu-item small-text p-1 pr-2"
                          >
                            <CCol col="1"></CCol>
                            <CCol col="7">
                              <CInput
                                v-if="
                                  isEditing ==
                                    'option' +
                                      optionIndex +
                                      'itemName' +
                                      itemIndex
                                "
                                v-model="item.name"
                                @blur="
                                  handleBlur(
                                    'option' +
                                      optionIndex +
                                      'itemName' +
                                      itemIndex
                                  )
                                "
                                @keyup.enter="
                                  isEditing =
                                    'option' +
                                    optionIndex +
                                    'itemPrice' +
                                    itemIndex
                                "
                                @keydown.prevent.tab="
                                  isEditing =
                                    'option' +
                                    optionIndex +
                                    'itemPrice' +
                                    itemIndex
                                "
                                v-focus
                              />
                              <div v-else>
                                <div
                                  @click="
                                    isEditing =
                                      'option' +
                                      optionIndex +
                                      'itemName' +
                                      itemIndex
                                  "
                                >
                                  {{ item.name }}
                                </div>
                              </div>
                            </CCol>
                            <CCol col="3">
                              <CInput
                                v-if="
                                  isEditing ==
                                    'option' +
                                      optionIndex +
                                      'itemPrice' +
                                      itemIndex
                                "
                                v-model="item.price"
                                @blur="
                                  handleBlur(
                                    'option' +
                                      optionIndex +
                                      'itemPrice' +
                                      itemIndex
                                  )
                                "
                                @keyup.enter="$event.target.blur()"
                                v-focus
                                prepend="$"
                              />
                              <div v-else>
                                <div
                                  @click="
                                    isEditing =
                                      'option' +
                                      optionIndex +
                                      'itemPrice' +
                                      itemIndex
                                  "
                                >
                                  ${{ item.price }}
                                </div>
                              </div>
                            </CCol>
                            <CCol
                              col="1"
                              class="text-right"
                              @click="onSubOptionsDelete(item)"
                            >
                              <CIcon name="cil-trash" size="sm" />
                            </CCol>
                          </CRow>
                        </transition-group>
                      </draggable>
                      <CRow class="menu-item small-text p-1 pr-2">
                        <CCol col="1"></CCol>
                        <CCol col="7" class="text-light">
                          <CInput
                            placeholder="Add A New Sub-Option"
                            v-if="
                              isEditing ==
                                'option' + optionIndex + 'itemNameNEW'
                            "
                            v-model="newItem.name"
                            @blur="
                              handleBlur('option' + optionIndex + 'itemNameNEW')
                            "
                            @keyup.enter="
                              isEditing =
                                'option' + optionIndex + 'itemPriceNEW'
                            "
                            @keydown.prevent.tab="
                              isEditing =
                                'option' + optionIndex + 'itemPriceNEW'
                            "
                            v-focus
                          />
                          <div v-else>
                            <div
                              @click="
                                isEditing =
                                  'option' + optionIndex + 'itemNameNEW'
                              "
                            >
                              <span v-if="newItem.name === ''">
                                Add A New Sub-Option
                              </span>
                              <span v-else>
                                {{ newItem.name }}
                              </span>
                            </div>
                          </div>
                        </CCol>
                        <CCol col="3" class="text-light">
                          <CInput
                            v-if="
                              isEditing ==
                                'option' + optionIndex + 'itemPriceNEW'
                            "
                            v-model="newItem.price"
                            @blur="
                              handleBlur(
                                'option' + optionIndex + 'itemPriceNEW'
                              )
                            "
                            @keyup.enter="$event.target.blur()"
                            v-focus
                            prepend="$"
                          />
                          <div v-else>
                            <div
                              @click="
                                isEditing =
                                  'option' + optionIndex + 'itemPriceNEW'
                              "
                            >
                              ${{ newItem.price }}
                            </div>
                          </div>
                        </CCol>
                        <CCol
                          col="1"
                          class="text-right"
                          @click="saveSubItems(option)"
                        >
                          <CIcon name="cil-save" size="sm" />
                        </CCol>
                      </CRow>
                    </CCollapse>
                  </div>
                </transition-group>
              </draggable>
              <CRow
                style="border-top: 1px solid lightgray;"
                class="menu-item small-text"
              >
                <CCol col="4" class="text-light">
                  <CInput
                    placeholder="Add A New Option"
                    v-if="isEditing === 'optionCategoryNEW'"
                    v-model="newOption.category"
                    @blur="handleBlur('optionCategoryNEW')"
                    @keyup.enter="isEditing = 'optionTypeNEW'"
                    @keydown.prevent.tab="isEditing = 'optionTypeNEW'"
                    v-focus
                  />
                  <div v-else>
                    <div @click="isEditing = 'optionCategoryNEW'">
                      <span v-if="newOption.category === ''">
                        Add A New Option
                      </span>
                      <span v-else>
                        {{ newOption.category }}
                      </span>
                    </div>
                  </div>
                </CCol>
                <CCol col="3" class="text-light">
                  <CSelect
                    placeholder="-- Type --"
                    :value.sync="newOption.type"
                    v-if="isEditing === 'optionTypeNEW'"
                    @blur="handleBlur('optionTypeNEW')"
                    @change="isEditing = 'optionMinNEW'"
                    @keydown.prevent.tab="isEditing = 'optionMinNEW'"
                    :options="[
                      { value: 'Required', label: 'Required' },
                      { value: 'Optional', label: 'Optional' },
                    ]"
                    v-focus
                  >
                  </CSelect>
                  <div v-else>
                    <div @click="isEditing = 'optionTypeNEW'">
                      {{ newOption.type }}
                    </div>
                  </div>
                </CCol>
                <CCol col="2" class="text-light">
                  <CSelect
                    placeholder="Min"
                    :value.sync="newOption.min"
                    v-if="isEditing == 'optionMinNEW'"
                    @blur="handleBlur('optionMinNEW')"
                    @change="isEditing = 'optionMaxNEW'"
                    @keydown.prevent.tab="isEditing = 'optionMaxNEW'"
                    :options="[
                      { value: '0', label: '0' },
                      { value: '1', label: '1' },
                      { value: '2', label: '2' },
                      { value: '3', label: '3' },
                      { value: '4', label: '4' },
                      { value: '5', label: '5' },
                      { value: '6', label: '6' },
                      { value: '7', label: '7' },
                      { value: '8', label: '8' },
                      { value: '9', label: '9' },
                      { value: '10', label: '10' },
                    ]"
                    v-focus
                  >
                  </CSelect>
                  <div v-else>
                    <div @click="isEditing = 'optionMinNEW'">
                      {{ newOption.min }}
                    </div>
                  </div>
                </CCol>
                <CCol col="2" class="text-light">
                  <CSelect
                    placeholder="Max"
                    :value.sync="newOption.max"
                    v-if="isEditing == 'optionMaxNEW'"
                    @blur="handleBlur('optionMaxNEW')"
                    @change="$event.target.blur()"
                    :options="[
                      { value: '0', label: '0' },
                      { value: '1', label: '1' },
                      { value: '2', label: '2' },
                      { value: '3', label: '3' },
                      { value: '4', label: '4' },
                      { value: '5', label: '5' },
                      { value: '6', label: '6' },
                      { value: '7', label: '7' },
                      { value: '8', label: '8' },
                      { value: '9', label: '9' },
                      { value: '10', label: '10' },
                    ]"
                    v-focus
                  >
                  </CSelect>
                  <div v-else>
                    <div @click="isEditing = 'optionMaxNEW'">
                      {{ newOption.max }}
                    </div>
                  </div>
                </CCol>
                <CCol lg="1" class="text-right" @click="saveOptions">
                  <CIcon name="cil-save" size="sm" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol sm="6"
              ><CButton color="light" @click="goBack">Cancel</CButton></CCol
            >
            <CCol sm="6" class="text-right"
              ><CButton color="primary" @click="onSaveEditClick"
                >Save</CButton
              ></CCol
            >
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthenticationService from "../../services/authenticationservice.js";
import DynamoDBService from "../../services/dynamodbservice.js";
import store from "../../store";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import UserRoles from "../../data/Roles.js";
import constants from "../../data/Constants.js";
import Draggable from "vuedraggable";
export default {
  name: "User",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      siteDetails: null,
      selectedSite: 0,
      tempData: [],
      selectedCategory: null,
      isImageEdited: false,
      imageData: "",
      fileSelected: null,
      selectedSites: [],
      showSite: false,
      siteData: [],
      siteFullDetails: [],
      isEditMode: false,
      credentials: null,
      itemsOpened: null,
      roles: UserRoles,
      isCollapsed: "",
      drag: false,
      isEditing: "",
      itemVal: {
        //item_number: 5,
        name: "",
        description: "",
        //category: "Small Bites & Salads (10am-2pm)",
        //subCategory: "Salads",
        prep_time: "",
        price: "",
        price_currency: "",
        image_url: "img/upload_photo.png",
        options: [],
        price_currency: "$",
        item_number: "0",
        item_enabled: true,
        category: "",
      },
      newOption: {
        category_number: "0",
        category: "",
        type: "Required",
        min: "min",
        max: "max",
      },
      newItem: {
        item_number: "0",
        name: "",
        price: "0.00",
      },
    };
  },
  components: {
    Draggable,
  },
  mounted: function() {
    this.itemData();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "options",
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true,
      };
    },
    dragSubOptions() {
      return {
        animation: 200,
        group: "suboptions",
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true,
      };
    },
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" },
      ];
    },
  },
  created() {
    if (this.credentials) {
      // have credentials
    } else {
      // no credentials
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getCredentials().then(
        (data) => {
          this.credentials = data;
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    }
  },
  directives: {
    focus: {
      // directive definition
      inserted: function(el, binding) {
        console.log(el.querySelector("input"));
        if (el.querySelector("input") != null) {
          el.querySelector("input").focus();
        }
        if (el.querySelector("select") != null) {
          el.querySelector("select").focus();
        }
      },
    },
  },
  methods: {
    handleBlur(control) {
      if (control == this.isEditing) {
        this.isEditing = "";
      }
    },
    onOptionsDelete(option) {
      var test = this.itemVal;
      this.itemVal.options.forEach((element, index, object) => {
        if (element.category == option.category) {
          object.splice(index, 1);
        }
      });
    },
    onSubOptionsDelete(subOption) {
      var test = this.itemVal;
      this.itemVal.options.forEach((element) => {
        element.items.forEach((element1, index, object) => {
          if (element1.item_number == subOption.item_number) {
            object.splice(index, 1);
          }
        });
      });
    },
    getSiteDetails(site_id) {
      var that = this;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
        Key: { site_id: site_id },
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getItem(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.siteDetails = data.Item;
            this.isCollapsed =
              "optionCategory" + (that.itemVal.options.length - 1);
            if (!that.isEditMode) {
              that.itemVal.item_number = String(
                that.selectedCategory.items.length + 1
              );
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    defineProp(obj, key, value) {
      var config = {
        value: value,
        writable: true,
        enumerable: true,
        configurable: true,
      };
      Object.defineProperty(obj, key, config);
    },
    saveSubItems(option) {
      this.itemVal.options.forEach((element) => {
        if (element.category_number == option.category_number) {
          if (element.items == undefined) {
            this.defineProp(element, "items", []);
          }
          element.items.push(JSON.parse(JSON.stringify(this.newItem)));
          var test = 0;
        }
      });
      var count = 0;
      this.itemVal.options.forEach((element) => {
        element.items.forEach((element1) => {
          count++;
          element1.item_number = this.itemVal.item_number + "." + count;
        });
      });

      this.newItem.name = "";
      this.newItem.price = "0.00";
      var test = 0;
    },
    saveOptions() {
      var test = this.newOption;
      var optionsCount = this.itemVal.options.length;
      this.newOption.category_number = String(optionsCount + 1);
      if (this.newOption.min == "min") {
        this.newOption.min = "0";
      }
      if (this.newOption.max == "max") {
        this.newOption.max = "0";
      }
      this.itemVal.options.push(JSON.parse(JSON.stringify(this.newOption)));
""
      this.newOption.category_number = "0";
      this.newOption.category = "";
      this.newOption.type = "Required";
      this.newOption.min = "min";
      this.newOption.max = "max";
      var test = 0;
    },
    itemData() {
      if (this.$route.query.selectedMenuItem != undefined) {
        this.isEditMode = true;
        this.itemVal = this.$route.query.selectedMenuItem;
        if (
          this.itemVal.image_url == "" ||
          this.itemVal.image_url == "img/photo_coming_soon.png"
        ) {
          this.itemVal.image_url = "img/upload_photo.png";
        }

        if (this.itemVal.item_enabled != undefined) {
          var x = true
          if(this.itemVal.item_enabled == "false"){
            x = false;
          }
          this.itemVal.item_enabled = x;
        }else{
          this.itemVal.item_enabled = true;
      }

        // remove mins from prep time since it's appended in box
        if (this.itemVal.prep_time.indexOf("min") > -1) {
          this.itemVal.prep_time = this.itemVal.prep_time.replace("min", "");
          this.itemVal.prep_time = this.itemVal.prep_time.trim();
        }
      } else {
        this.isEditMode = false;
      }
      this.selectedCategory = this.$route.query.selectedCategory;
      this.selectedCategory.items.forEach((element, index, object) => {
        if (
          element.image_url == "" ||
          element.image_url == "img/photo_coming_soon.png"
        ) {
          this.selectedCategory.items[index].image_url = "img/upload_photo.png";
        }
      });
      this.selectedSite = this.$route.query.selectedSite;
      this.getSiteDetails(this.selectedSite);
    },
    goBack() {
      this.$router.push({ path: "/menus" });
    },
    onFileSelected(event) {
      this.isImageEdited = true;
      this.fileSelected = event[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.fileSelected);
      //this.uploadToS3(this.fileSelected)
    },

    onRemoveItem() {
      var test = this.itemVal;
      this.siteDetails.site_menu.forEach((element) => {
        element.items.forEach((element1, index, object) => {
          if (element1.item_number == this.itemVal.item_number) {
            object.splice(index, 1);
          }
        });
      });
      this.updateDB();
    },
    onSaveEditClick() {
      if (
        this.itemVal.name == undefined ||
        this.itemVal.name == null ||
        this.itemVal.name == ""
      ) {
        this.$alert(constants.NAME_VALIDATION, constants.INFORMATION);
        return;
      }
      if (
        this.itemVal.prep_time == undefined ||
        this.itemVal.prep_time == null ||
        this.itemVal.prep_time == ""
      ) {
        this.$alert(constants.SITE_PREP_TIME, constants.INFORMATION);
        return;
      }
      if (
        this.itemVal.price == undefined ||
        this.itemVal.price == null ||
        this.itemVal.price == ""
      ) {
        this.$alert(constants.SITE_PRICE, constants.INFORMATION);
        return;
      }

      if (
        this.itemVal.description == undefined ||
        this.itemVal.description == null ||
        this.itemVal.description == ""
      ) {
        this.$alert(constants.SITE_DESCRIPTION, constants.INFORMATION);
        return;
      }

      var x = this.itemVal.item_enabled.toString();
      this.itemVal.item_enabled = x;

      if (this.fileSelected != null) {
        this.uploadToS3(this.fileSelected);
      } else {
        this.selectedCategory.items.push(this.itemVal);
        this.siteDetails.site_menu.forEach((element) => {
          if (element.category == this.selectedCategory.category) {
            if (this.isEditMode) {
              element.items.forEach((element1, index, object) => {
                if (element1.item_number == this.itemVal.item_number) {
                  //object.splice(index, 1);
                  element.items[index] = this.itemVal;
                }
              });
            } else {
              var number = this.generateUniqueSiteID(); //element.items.length + 1;
              this.itemVal.item_number = String(number);
              element.items.push(this.itemVal);
            }

            //element.items.push(this.itemVal);
          }
        });
        this.updateDB();
      }

      var test = this.selectedCategory;
      var test1 = this.itemVal;
    },
    generateUniqueSiteID() {
      var uniqueVal = 0;
      uniqueVal = Date.now();
      return String(uniqueVal);
    },
    updateDB() {
      this.siteDetails.site_menu.forEach((element) => {
        if (element.category == this.selectedCategory.category) {
          element.items.forEach((element1, index, object) => {
            if (element1.item_number == this.itemVal.item_number) {
              element.items[index].prep_time =
                element.items[index].prep_time + " min";
            }
          });
        }
      });

      var params = {
        TableName: constants.SITES_TABLE_NAME,
        Key: { site_id: this.selectedSite },

        UpdateExpression: `set #v1 = :site_menu_val`,
        ExpressionAttributeNames: {
          "#v1": "site_menu",
        },
        ExpressionAttributeValues: {
          ":site_menu_val": this.siteDetails.site_menu,
        },

        ReturnValues: "ALL_NEW",
      };

      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.updateItem(params).then(
        (data) => {
          console.log(data);
          this.$router.push({ path: "/menus" });
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    uploadToS3(file) {
      var params = {
        Bucket: constants.S3_BUCKET_NAME,
        Key:
          this.selectedSite +
          "_" +
          this.selectedCategory.category_number +
          "_" +
          this.itemVal.item_number +
          ".png",
        Body: file,
      };

      const dynamoDBService = new DynamoDBService();
      dynamoDBService.uploadToS3(params).then(
        (data) => {
          console.log(data);
          this.itemVal.image_url = data;

          this.selectedCategory.items.push(this.itemVal);
          this.siteDetails.site_menu.forEach((element) => {
            if (element.category == this.selectedCategory.category) {
              if (this.isEditMode) {
                element.items.forEach((element1, index, object) => {
                  if (element1.item_number == this.itemVal.item_number) {
                    //object.splice(index, 1);
                    element.items[index] = this.itemVal;
                  }
                });
              } else {
                var number = this.generateUniqueSiteID();//element.items.length + 1;
                this.itemVal.item_number = String(number);
                element.items.push(this.itemVal);
              }

              //element.items.push(this.itemVal);
            }
          });
          this.updateDB();
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
  },
};
</script>
